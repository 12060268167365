<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{ page_name }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-upload
                                style="margin-left: 10px;"
                                v-if="is_auth('wxmp.material.upload')"
                                class="upload-demo"
                                :action="upload_url"
                                name="filedata"
                                :data="upload_data"
                                :show-file-list="false"
                                :on-progress="()=>{
                                    this.loading = true
                                }"
                                :on-success="uploadok"
                                :limit="999">
                            <el-button size="medium" icon="el-icon-plus" type="primary">上传图片素材</el-button>
                        </el-upload>

                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    size="medium"
                    border
                    v-loading="loading"
                    :data="tableData"
                    style="width: 100%">
                <el-table-column
                        prop="type"
                        label="类型">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.type==='image'">图片</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="media_id"
                        label="media_id">
                </el-table-column>
                <el-table-column
                        prop="type"
                        label="素材">
                    <template slot-scope="scope">
                        <div v-if="scope.row.type==='image'">
                            <el-image
                                    style="width: 22px; height: 22px; margin-right: 4px;"
                                    :src="scope.row.url_qn"
                                    :preview-src-list="[scope.row.url_qn]"
                                    fit="contain">
                            </el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="info"
                        label="原始内容">
                </el-table-column>
                <el-table-column
                        prop="create_time"
                        label="添加时间">
                </el-table-column>
                <el-table-column
                        fixed="right"
                        label="操作"
                        width="150">
                    <template slot-scope="scope">
                        <el-button
                                v-if="is_auth('wxmp.material.isdel')"
                                @click="isdel(scope.row)"
                                type="danger"
                                size="mini">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination
                    @current-change="getlist"
                    :page-size="this.env.pageSize"
                    :pager-count="7"
                    background
                    layout="prev, pager, next, total"
                    :current-page.sync="page"
                    :total="count">
            </el-pagination>

        </div>
    </div>
</template>
<script>
import env from '@/../config/env'

export default {
    data() {
        return {
            upload_url: env.API_URL + '/api/oss',
            upload_data: {
                'api_name': 'wxmp.material.upload',
                token: this.Tool.get_l_cache('token') ?? '',
            },

            page_name: '素材管理',
            loading: true,      // 加载状态
            tableData: [],      // 列表内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            info: {
                type: 1,
                state: 1,
            },           // 详情
            fileList: [],
        }
    },
    // 创建
    created() {
        this.getlist()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "wxmp.material.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            console.log('postdata', postdata)

            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 删除
        isdel(info) {
            let postdata = {
                api_name: 'wxmp.material.isdel',
                token: this.Tool.get_l_cache('token'),
                id: info.id,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.editPage = false
                    this.$message({
                        message: '删除成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()
                        }
                    });

                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 上传完成
        uploadok(json) {
            this.loading = false
            if (json.code === 0) {
                this.$message({
                    message: '上传成功', type: 'success',
                    duration: this.env.message_duration,
                    onClose: () => {
                        this.getlist()
                    }
                });

            } else {
                this.$message.error(json.message);
            }


        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*表格不换行*/
>>> .el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}

.form-tag_colour-div {
    min-height: 200px;
}

</style>
